import { Alert, Button } from 'react-bootstrap'
const Error = ({
  heading = 'Oops!',
  children = (
    <div>
      <p className="lead">The server crashed during its workout :)</p>
      <p>
        We have logged the error and will start an investigation. <br />
        If the error persists, please contact customer support [TODO]
      </p>
    </div>
  ),
  variant = 'danger',
  showRefresh = true,
}) => {
  return (
    <Alert variant={variant} className="text-center">
      <Alert.Heading>{heading}</Alert.Heading>
      {children ? <div>{children}</div> : null}
      {showRefresh && (
        <Button onClick={() => window.location.replace('/')}>
          Refresh Homepage
        </Button>
      )}
    </Alert>
  )
}

export default Error
