export const API_ENDPOINT_WORKOUTS =
  'https://lk49cdsvp1.execute-api.us-west-2.amazonaws.com/develop'

export const API_ENDPOINT_USERPROFILE =
  'https://ymlds473oc.execute-api.us-west-2.amazonaws.com/develop'

export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_uxk8HWcVF',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1n87ludrt0aede59748irmlq67',

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // Hosted UI configuration
    oauth: {
      domain: 'outworkit-develop.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'https://dev.app.outworkit.com/',
      redirectSignOut: 'https://dev.app.outworkit.com/',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
}
