import { useEffect, useState } from 'react'
import {
  authenticateUser,
  signIn,
  signOut,
  getUserProfile,
} from '../api/usersApi'

const useAuth = () => {
  const [user, setUser] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [isAuthError, setIsAuthError] = useState(false)

  useEffect(() => {
    if (!user) return

    getUserProfile().then(
      (profile) => {
        setUserProfile(profile)
        setIsAuthLoading(false)
      },
      () => {
        setIsAuthError(true)
        setIsAuthLoading(false)
      }
    )
  }, [user])

  useEffect(() => {
    //todo: can we just check the user here after login?
    authenticateUser()
      .then((user) => {
        setUser(user)
      })
      .catch(() => {
        setUser(null)
        setIsAuthLoading(false)
      })
  }, []) //todo this only works because the hosted UI redirects and forces a full load

  return {
    user,
    isAuthLoading,
    isAuthError,
    isAuthenticated: user != null,
    signIn,
    signOut,
    setUserProfile,
    userProfile,
  }
}

export default useAuth
