import { Suspense } from 'react'
import ErrorBoundary from '../ErrorBoundary'
import Loader from '../Loader'

const handleLazy = (LazyComponent) => (additionalProps) => (
  <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <LazyComponent {...additionalProps} />
    </Suspense>
  </ErrorBoundary>
)

export default handleLazy
