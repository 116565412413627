export const API_ENDPOINT_WORKOUTS =
  'https://1gvx3btwx5.execute-api.us-west-2.amazonaws.com/main'

export const API_ENDPOINT_USERPROFILE =
  'https://ilsnp6wvd2.execute-api.us-west-2.amazonaws.com/main'

export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_9XccrO7NU',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1ejlt07ts8n316kvh4vuue2kd4',

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // Hosted UI configuration
    oauth: {
      domain: 'outworkit-main.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'https://outworkit.com/',
      redirectSignOut: 'https://outworkit.com/',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
}
