import { Navbar, Container } from 'react-bootstrap'

//todo just combine with navbar, no need for this base
const LayoutNavbar = ({
  expand = 'md',
  bg = 'dark',
  variant = 'dark',
  leftChildren,
  children,
  containerClassName,
  ...rest
}) => (
  <Navbar expand={expand} bg={bg} variant={variant} {...rest}>
    <Container className={containerClassName}>
      {leftChildren}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">{children}</Navbar.Collapse>
    </Container>
  </Navbar>
)

export default LayoutNavbar
