import { Link } from 'react-router-dom'
import styles from './SiteFooter.module.scss'

const SiteFooter = () => (
  <footer
    className={`${styles.SiteFooter} text-center my-md-5 pt-5 border-top`}
  >
    <div>
      <ul className="list-inline text-muted">
        <li className="list-inline-item">
          <Link to="/" className="pr-2">
            Home
          </Link>
        </li>
        <li className="list-inline-item">
          <Link to="privacy-policy" className="pr-2">
            Privacy Policy
          </Link>
        </li>
        <li className="list-inline-item">
          <Link to="terms-and-conditions">Terms &amp; Conditions</Link>
        </li>
      </ul>
    </div>
    <div className="text-muted">
      &copy; {new Date().getFullYear()} Ronin Software Solutions, LLC
    </div>
  </footer>
)

export default SiteFooter
