import { useRef, useEffect } from 'react'
import classNames from 'classnames'
import './Loader.scss'

const Loader = ({ className = '', size = 'md', color = '', ...rest }) => {
  const loaderEle = useRef()

  useEffect(() => {
    const loader = loaderEle.current.querySelector('.loader')

    if (loader && !loader.querySelector('svg')) {
      const siteLoaderTemplateImg = document
        .querySelector('#site-loader-template')
        ?.querySelector('svg')
        ?.cloneNode(true)
      loader.append(siteLoaderTemplateImg)
    }
  }, [loaderEle])

  return (
    <div ref={loaderEle} className={classNames(className)} {...rest}>
      <span className="sr-only">Loading...</span>
      <div className={classNames('loader', size, color)}></div>
    </div>
  )
}

export default Loader
