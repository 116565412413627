import { Button } from 'react-bootstrap'
import Loader from '../_effects/Loader'
import classNames from 'classnames'

const AppButton = ({
  children,
  isLoading = false,
  loaderColor = 'white',
  onClick,
  icon,
  className,
  ...rest
}) => {
  return (
    <Button
      disabled={isLoading}
      onClick={!isLoading ? onClick : null}
      className={classNames(
        className,
        'd-flex align-items-center justify-content-center'
      )}
      {...rest}
    >
      {isLoading && (
        <Loader size="xs" color={loaderColor} className="d-inline-block mr-1" />
      )}
      {icon && <span className="btn-icon">{icon}</span>}
      <span className="d-inline-block">{children}</span>
    </Button>
  )
}

export default AppButton
