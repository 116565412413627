import Auth from '@aws-amplify/auth'
import createApiFetch from '../../_api/createApiFetch'
import { API_ENDPOINT_USERPROFILE } from '../../_config/service-layer'

export const signIn = (provider) =>
  Auth.federatedSignIn(provider ? { provider } : undefined)
export const signOut = () => Auth.signOut()

export const getUserAuthToken = async () => {
  const user = await Auth.currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}

const apiFetch = createApiFetch(API_ENDPOINT_USERPROFILE, getUserAuthToken)

export const authenticateUser = async () => {
  const {
    attributes: { email, sub: id },
  } = await Auth.currentAuthenticatedUser()

  return { email, id }
}

export const getUserProfile = async () => {
  try {
    const profile = await apiFetch({ route: '/me' })
    return profile
  } catch (error) {
    const { status, customError: { error: message } = {} } = error
    if (status === 404 && message === 'SERVER_ITEM_NOTFOUND') return null

    throw error
  }
}

export const saveUserProfile = async (profile) => {
  //validate first
  if (!validateProfile(profile)) throw new Error('NO_SCHEDULES_ENABLED')

  await apiFetch({ route: '/me', params: { method: 'POST', body: profile } })
}

const validateProfile = (userProfile) => {
  const { cardioSchedule, strengthTrainingSchedule } = userProfile
  return strengthTrainingSchedule.enabled || cardioSchedule.enabled
}
