import { Link } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import Logo from '../../_themes/logos/svg/logo.svg'
import classNames from 'classnames'

const NavBrand = ({ isInterior = false }) => {
  return (
    <Navbar.Brand
      as={Link}
      to="/"
      className="text-primary d-flex align-items-center m-0 p-0 pr-2"
    >
      <img src={Logo} alt="" width="43.19px" height="40px" />
      <span className={classNames('pl-2', { 'sr-only': isInterior })}>
        outWorkIt
      </span>
    </Navbar.Brand>
  )
}

export default NavBrand
