import { Container } from 'react-bootstrap'
import AppNavbar from '../_navigation/components/AppNavbar'
import SiteFooter from '../_navigation/components/SiteFooter'

const Layout = ({ children }) => (
  <>
    <AppNavbar />
    <Container as="main">{children}</Container>
    <SiteFooter />
  </>
)

export default Layout
