import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const trackPage = (pagePath) => {
  if (!window.gtag) return
  if (
    process.env.NODE_ENV === 'production' &&
    !process.env.REACT_APP_DEPLOYMENT_ENV
    //only track if prod build running in prod env
  ) {
    window.gtag('config', window.gtagTrackingId, {
      page_path: pagePath,
    })
  }
}
const useTracking = () => {
  const { listen } = useHistory()

  //for route changes
  useEffect(() => {
    const unlisten = listen((location) => {
      trackPage(location.pathname)
    })

    return unlisten
  }, [listen])

  //for the first load
  useEffect(() => {
    trackPage(document.location.pathname)
  }, [])
}

export default useTracking
