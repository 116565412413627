// import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from './auth/context/AuthContext'
import { CheckNetworkProvider } from './_network/context/CheckNetworkContext'
import { AppQueryClientProvider } from './_api/context/AppQueryClientProvider'
import SiteRouter from './_navigation/routing/SiteRouter'

export const App = () => {
  return (
    <CheckNetworkProvider>
      <AuthProvider>
        <AppQueryClientProvider>
          <SiteRouter />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </AppQueryClientProvider>
      </AuthProvider>
    </CheckNetworkProvider>
  )
}
