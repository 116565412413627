import { useContext, lazy } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'

import { AuthContext } from '../../auth/context/AuthContext'
import { CheckNetworkContext } from '../../_network/context/CheckNetworkContext'
import OfflineError from '../../_network/components/OfflineError'
import Layout from '../../_layouts/Layout'
import ErrorBoundary from '../../_effects/ErrorBoundary'
import Error from '../../_effects/Error'
import Loader from '../../_effects/Loader'
import handleLazy from '../../_effects/hoc/handleLazy'
import useTracking from '../../_analytics/hooks/useTracking'

const PrivacyPolicy = lazy(() =>
  import('../../marketing/containers/PrivacyPolicy')
)
const TermsAndConditions = lazy(() =>
  import('../../marketing/containers/TermsAndConditions')
)
const Workouts = lazy(() => import('../../workouts/containers/Workouts'))
const Admin = lazy(() => import('../../workouts/containers/Admin'))

const Preferences = lazy(() =>
  import('../../preferences/containers/Preferences')
)
const GettingStarted = lazy(() =>
  import('../../marketing/containers/GettingStarted')
)

const NotAuthenticatedRoutes = () => (
  <Switch>
    <Route path="/" exact render={handleLazy(GettingStarted)} />
    <Route path="/privacy-policy" render={handleLazy(PrivacyPolicy)} />
    <Route
      path="/terms-and-conditions"
      render={handleLazy(TermsAndConditions)}
    />
    <Redirect to="/" />
  </Switch>
)

const AuthenticatedRoutes = () => (
  <Switch>
    <Route path="/" exact render={handleLazy(Workouts)} />
    <Route path="/workout-preferences" render={handleLazy(Preferences)} />
    <Route path="/privacy-policy" render={handleLazy(PrivacyPolicy)} />
    <Route
      path="/terms-and-conditions"
      render={handleLazy(TermsAndConditions)}
    />
    <Route path="/admin" render={handleLazy(Admin)} />
    <Redirect to="/" />
  </Switch>
)

const RouterContent = () => {
  useTracking()
  const { isAuthenticated, isAuthLoading, isAuthError } =
    useContext(AuthContext)

  const { isOnline } = useContext(CheckNetworkContext)

  return (
    <ErrorBoundary>
      <Layout>
        {!isOnline ? (
          <OfflineError />
        ) : (
          <>
            {isAuthError && <Error />}
            {isAuthLoading && <Loader />}
            {!isAuthLoading &&
              !isAuthError &&
              (isAuthenticated ? (
                <AuthenticatedRoutes />
              ) : (
                <NotAuthenticatedRoutes />
              ))}
          </>
        )}
      </Layout>
    </ErrorBoundary>
  )
}

const SiteRouter = () => {
  //useTracking()

  return (
    <Router>
      <RouterContent />
    </Router>
  )
}

export default SiteRouter
