const createApiFetch = (apiEndpoint, getUserAuthTokenFn) => async ({
  route,
  params = {},
}) => {
  const authToken = await getUserAuthTokenFn()
  const authHeaders = authToken ? { Authorization: `Bearer ${authToken}` } : {}
  const { body } = params
  if (body) {
    if (typeof body === 'object') {
      params = { ...params, body: JSON.stringify(body) }
    }
  }
  const result = await fetch(`${apiEndpoint}${route}`, {
    ...params,
    headers: { ...(params.headers || {}), ...authHeaders },
  })
  const status = result.status
  const content = await result.json()
  // eslint-disable-next-line no-console

  if (status < 200 || status >= 300) {
    const error = new Error(`${status}: server error`)
    error.customError = content
    error.status = status
    throw error
  }

  return content
}

export default createApiFetch
