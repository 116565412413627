import Amplify from '@aws-amplify/core'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const resolvedConfigEnv =
  process.env.REACT_APP_DEPLOYMENT_ENV || process.env.NODE_ENV

const {
  amplifyConfig,
  API_ENDPOINT_WORKOUTS,
  API_ENDPOINT_USERPROFILE,
} = require(`./env-${resolvedConfigEnv}`)

const configureAuthService = () => {
  Amplify.configure(amplifyConfig)
}

const configureSentryLogging = () => {
  Sentry.init({
    dsn:
      'https://0fd860a765cf4f74877dd82130ff4d2b@o241984.ingest.sentry.io/1415938',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 1.0,
  })
}

export {
  configureAuthService,
  configureSentryLogging,
  API_ENDPOINT_WORKOUTS,
  API_ENDPOINT_USERPROFILE,
}
