import { useState, useEffect, useCallback, useRef } from 'react'
const testUrl = `${process.env.PUBLIC_URL}/online-check.html`

const interval = 2000

const fetchTest = async () => {
  try {
    const { status } = await fetch(`${testUrl}?${Date.now()}`)
    return status === 200
  } catch {
    return false
  }
}

const useCheckNetwork = () => {
  const [isOnline, setIsOnline] = useState(true)
  const timer = useRef(null)

  const runFetchTest = useCallback(() => {
    fetchTest().then((online) => {
      //todo useReducer here
      if (isOnline !== online) {
        setIsOnline(online)
      }
      timer.current = setTimeout(runFetchTest, interval)
    })
  }, [setIsOnline, isOnline])

  const clearFetchTest = useCallback(() => clearTimeout(timer.current), [])

  useEffect(() => {
    runFetchTest()
    return clearFetchTest
  }, [runFetchTest, clearFetchTest])

  return { isOnline }
}

export default useCheckNetwork
