import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavDropdown } from 'react-bootstrap'
import { AuthContext } from '../../auth/context/AuthContext'
import LoginPrompt from '../../auth/components/LoginPrompt'
import NavBrand from './NavBrand'
import LayoutNavbar from './LayoutNavbar'
import styles from './AppNavbar.module.scss'

const AppNavbar = () => {
  const { user, isAuthenticated } = useContext(AuthContext)

  return (
    <LayoutNavbar
      expand="md"
      bg="light"
      variant="light"
      fixed="top"
      className={styles.AppNavbar}
      leftChildren={<NavBrand isInterior={isAuthenticated} />}
    >
      {isAuthenticated && (
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} exact to="/">
            Workouts
          </Nav.Link>
          <Nav.Link as={NavLink} exact to="/workout-preferences">
            Workout Preferences
          </Nav.Link>
        </Nav>
      )}
      <Nav className="ml-auto">
        <NavDropdown
          title={isAuthenticated ? user.email : 'Sign In / Sign Up'}
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item as="div">
            <LoginPrompt size="lg" />
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </LayoutNavbar>
  )
}

export default AppNavbar
