import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ImGoogle2, ImFacebook2 } from 'react-icons/im'
import AppButton from '../../_controls/AppButton'

const LoginPrompt = ({ size = 'lg' }) => {
  const { signIn, signOut, isAuthenticated } = useContext(AuthContext)
  const createSocialLoginBtn = (provider, Icon) => (
    <AppButton
      onClick={() => signIn(provider)}
      variant="primary"
      className="w-100 my-2"
      size={size}
      icon={<Icon />}
    >
      Sign in with {provider}
    </AppButton>
  )

  return (
    <>
      {isAuthenticated ? (
        <AppButton
          onClick={signOut}
          variant="secondary"
          size="lg"
          className="w-100"
        >
          Sign out
        </AppButton>
      ) : (
        <>
          {createSocialLoginBtn('Google', ImGoogle2)}
          {createSocialLoginBtn('Facebook', ImFacebook2)}
        </>
      )}
    </>
  )
}

export default LoginPrompt
