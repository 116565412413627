//why this file? Amplify can't handle multiple
//redirect urls https://github.com/aws-amplify/amplify-cli/issues/2792

import {
  amplifyConfig as amplifyConfigDev,
  API_ENDPOINT_WORKOUTS,
  API_ENDPOINT_USERPROFILE,
} from './env-development'

const amplifyConfig = {
  ...amplifyConfigDev,
  Auth: {
    ...amplifyConfigDev.Auth,
    oauth: {
      ...amplifyConfigDev.Auth.oauth,
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
    },
  },
}
export { API_ENDPOINT_WORKOUTS, API_ENDPOINT_USERPROFILE, amplifyConfig }
