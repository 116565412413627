import { createContext } from 'react'
import useCheckNetwork from '../hooks/useCheckNetwork'

export const CheckNetworkContext = createContext()

export const CheckNetworkProvider = ({ children }) => {
  const { isOnline } = useCheckNetwork()
  return (
    <CheckNetworkContext.Provider value={{ isOnline }}>
      {children}
    </CheckNetworkContext.Provider>
  )
}
