import Error from '../../_effects/Error'

const OfflineError = () => {
  return (
    <Error heading="Currently Offline" showRefresh={false}>
      <p className="lead">
        It appears you do not have a solid internet connection. This app will be
        temporarily disabled until you connect again.
      </p>
    </Error>
  )
}

export default OfflineError
